import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 225.000000 225.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" >
<path d="M865 1838 c-130 -45 -245 -163 -274 -280 -32 -135 8 -297 94 -378
l26 -24 121 122 122 121 -22 24 c-29 31 -28 72 3 102 21 22 33 25 97 25 l73 0
144 144 143 143 -45 12 c-27 6 -125 11 -234 11 -165 -1 -195 -3 -248 -22z"/>
<path d="M1317 1682 c-70 -70 -127 -130 -127 -134 0 -3 17 -4 39 -1 34 5 40 2
62 -28 12 -18 30 -56 40 -83 l16 -51 84 -3 c46 -2 121 0 167 3 l82 7 0 47 c0
66 -26 158 -60 211 -39 60 -145 160 -170 160 -3 0 -63 -57 -133 -128z"/>
<path d="M880 1246 c-68 -69 -122 -127 -120 -129 53 -41 167 -83 355 -131 212
-55 265 -87 265 -162 0 -31 -26 -82 -54 -106 -18 -15 -43 -17 -183 -18 -160 0
-162 0 -193 26 -36 31 -64 82 -81 148 -6 25 -16 49 -21 52 -9 6 -237 -12 -292
-23 -28 -5 -29 -6 -23 -62 19 -193 137 -359 304 -426 61 -24 71 -25 298 -25
210 0 242 2 293 20 125 44 219 137 267 263 42 112 33 274 -20 377 -43 83 -141
160 -260 206 -57 21 -310 93 -390 111 -15 3 -51 -27 -145 -121z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
